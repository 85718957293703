<template>
  <div class="home">
    <v-row no-gutters>
      <v-col cols="7">
        <div @click="goBack()" class="mt-7 go-back">
          <img src="../assets/Images/back-black.svg" alt="cart" />
        </div>
        <div class="mt-3 font-30 butler-700 d-flex">Create Customization - Step 2</div></v-col
      >
      <v-col cols="5">
        <div class="mt-6 mb-3 ml-8 px-16">
          <p class="text-black mb-2">Select Status</p>
          <v-btn-toggle v-model="selectedStatus" dense color="black" class="select-color">
            <v-btn value="draft"> Draft </v-btn>
            <v-btn value="approve"> Approve </v-btn>
            <v-btn value="publish"> Publish </v-btn>
          </v-btn-toggle>
        </div>
      </v-col>
    </v-row>
    <v-card>
      <div class="container">
        <div class="row">
          <div class="col-6">
            <div class="category-tree-2">
              <div class="row">
                <div class="col-6">
                  <div class="font-22 poppins-medium mb-2">Upload Images</div>
                </div>
                <div class="col-6">
                  <v-col cols="12" class="dialog-table mt-2">
                    <div class="font-20 mt-0">Fetch Images</div>
                    <div class="font-16">(Google Drive Api)</div>
                    <v-text-field
                      placeholder="folder name"
                      class="mx-6 ml-0"
                      v-model="BulkfolderName"
                    ></v-text-field>
                    <v-btn
                      color="green darken-1"
                      @click="uploadFolderImages()"
                      class="mb-4 mt-6 float-right mr-2"
                      :disabled="fetching"
                      :loading="fetching"
                    >
                      Fetch
                    </v-btn>
                  </v-col>
                </div>
              </div>
              <ul class="px-0 pt-1">
                <li
                  class="text-capitalize"
                  style="display: block"
                  v-for="(category, index) in categories"
                  v-bind:key="index"
                >
                  <span class="butler-bold text-decoration-underline">CAT {{ index + 1 }}</span> -
                  {{ category.name }}

                  <ul class="px-0 pt-1">
                    <li
                      class="text-capitalize"
                      style="display: block"
                      v-for="(layer, index) in category.layers"
                      v-bind:key="index"
                    >
                      <span class="butler-bold text-decoration-underline">LAY {{ index + 1 }}</span>
                      - {{ layer.name }} --
                      <span class="show-button" v-if="layer.typeOfLayer == 'Embroidery'">
                        <button
                          type="button"
                          @click="embroideryMappingClick(category, layer)"
                          class="btn btn-outline-info btn-sm"
                        >
                          <span v-show="!layer.show">Embroidery Mapping</span>
                          <span v-show="layer.show">Embroidery Mapping</span>
                        </button></span
                      >
                      <div
                        v-if="layer.show && layer.typeOfLayer == 'Embroidery'"
                        class="image-upload-section"
                      >
                        <div>
                          <div>
                            <div>
                              <div v-if="layer.typeOfLayer == 'Embroidery'">
                                <div>
                                  <h6>Embroidery Mapping Per Category</h6>
                                  <table class="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th>Sr. No.</th>
                                        <th>Category</th>
                                        <th>Embroidery</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        v-for="(
                                          embroideryMappingObj, index
                                        ) in layer.embroideryMapping"
                                        v-bind:key="index"
                                      >
                                        <td>{{ index + 1 }}</td>
                                        <td>
                                          {{
                                            findCategoryName(
                                              embroideryMappingObj.categoryId,
                                              embroideryMappingObj.name
                                            )
                                          }}
                                        </td>
                                        <td>
                                          <select
                                            class="form-control"
                                            id="model"
                                            v-model="embroideryMappingObj.flag"
                                            @change="changeEmbOption($event, embroideryMappingObj)"
                                          >
                                            <option value="false">Disable</option>
                                            <option value="true">Enable</option>
                                          </select>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <span class="sub-text">- Change of {{ layer.typeOfLayer }}</span>
                      <ul class="px-0 pb-1">
                        <li
                          class="text-capitalize mt-1"
                          style="display: block"
                          v-for="(toggle, index) in layer.toggles"
                          v-bind:key="index"
                        >
                          <div>
                            <span class="butler-bold text-decoration-underline"
                              >TOG {{ index + 1 }}</span
                            >
                            -
                            {{ toggle.name }}
                            <span class="show-button" v-if="layer.typeOfLayer == 'Image'">
                              <button
                                type="button"
                                @click="toggleToggleShow(layer, toggle, category)"
                                class="btn btn-outline-info btn-sm"
                              >
                                <span v-show="!toggle.show">Show</span>
                                <span v-show="toggle.show">Hide</span>
                              </button></span
                            >
                            <div
                              v-if="toggle.show && layer.typeOfLayer == 'Image'"
                              class="image-upload-section"
                            >
                              <div class="image-selection category-section py-6">
                                <div class="font-16 poppins-700">
                                  <div class="d-flex">
                                    <div>Core Images</div>
                                    <div>
                                      <!-- <v-btn dense height="30" class="ml-2" @click="addBase(toggle)"
                                        >Add</v-btn
                                      > -->
                                      <img
                                        @click="addBase(toggle)"
                                        src="@/assets/Images/add.svg"
                                        alt="logo"
                                        class="ml-2"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  v-for="(baseType, baseIndex) in toggle.baseTypes"
                                  :key="baseType.index"
                                  class="mt-6"
                                >
                                  <span class="show-button mt-2" v-if="category.embroideryAssigned">
                                    <button
                                      type="button"
                                      @click="
                                        toggleEmbroidery(
                                          layer,
                                          toggle,
                                          category,
                                          baseType,
                                          baseIndex
                                        )
                                      "
                                      class="btn btn-info btn-sm"
                                    >
                                      <span v-show="!baseType.showEmbroidery">Show Embroidery</span>
                                      <span v-show="baseType.showEmbroidery">Hide Embroidery</span>
                                    </button></span
                                  >

                                  <div>
                                    <!-- <v-btn
                                      dense
                                      height="30"
                                      class="ml-2"
                                      @click="removeBase(toggle, baseType)"
                                      >Remove base</v-btn
                                    > -->
                                    {{ baseType.name }}
                                    <img
                                      @click="removeBase(toggle, baseType)"
                                      src="@/assets/Images/remove.svg"
                                      alt="logo"
                                      class="pl-1"
                                    />
                                  </div>

                                  <div class="row">
                                    <div class="col-md-3">
                                      <div class="form-group">
                                        <label
                                          for="layer-sequence"
                                          class="font-9 poppins-bold toggle-name-text"
                                          >{{ `${toggle.name}-MF-D${baseIndex + 1}` }}</label
                                        >
                                        <div>
                                          <button
                                            type="button"
                                            class="btn btn-outline-primary btn-sm font-8"
                                            @click="
                                              normalFront = true;
                                              selectedBaseId = baseType.id;
                                            "
                                          >
                                            Choose Image
                                          </button>

                                          <div class="image-preview mt-3 mb-8">
                                            <v-card
                                              height="150"
                                              v-if="
                                                baseType &&
                                                baseType.toggleImageBody &&
                                                baseType.toggleImageBody.length > 0 &&
                                                baseType.toggleImageBody[0] &&
                                                baseType.toggleImageBody[0].image
                                              "
                                            >
                                              <img
                                                @click="baseType.toggleImageBody[0].image = ''"
                                                src="@/assets/Images/remove.svg"
                                                alt="logo"
                                                class="pl-14"
                                              />
                                              <v-img
                                                :lazy-src="baseType.toggleImageBody[0].image"
                                                :src="baseType.toggleImageBody[0].image"
                                              ></v-img>
                                            </v-card>
                                          </div>
                                          <v-row justify="center">
                                            <v-dialog v-model="normalFront" max-width="700">
                                              <v-card>
                                                <div class="text-center font-22 mb-4 pt-4">
                                                  Upload Image
                                                  <div
                                                    class="
                                                      font-13
                                                      black--text
                                                      amber
                                                      darken-5
                                                      font-weight-bold
                                                      size-info
                                                      px-1
                                                    "
                                                  >
                                                    S-M ( 283 W &#10005; 452 H)
                                                  </div>
                                                  <div
                                                    class="
                                                      font-13
                                                      black--text
                                                      amber
                                                      darken-5
                                                      font-weight-bold
                                                      size-info
                                                      px-1
                                                    "
                                                  >
                                                    L-XL ( 336 W &#10005; 452 H)
                                                  </div>
                                                </div>
                                                <v-row no-gutters>
                                                  <v-col
                                                    cols="5"
                                                    class="dialog-table px-2 mx-4 mb-4"
                                                  >
                                                    <v-card-title>Choose from Device</v-card-title>
                                                    <input
                                                      type="file"
                                                      class="form-control mt-4"
                                                      id="file"
                                                      @change="
                                                        imagePickerClick(
                                                          $event,
                                                          layer,
                                                          toggle,
                                                          baseType,
                                                          'Normal Front',
                                                          'image'
                                                        )
                                                      "
                                                      :key="componentFrontKey"
                                                      accept="image/*"
                                                    />
                                                  </v-col>
                                                  <v-col cols="1"
                                                    ><div class="text-center mt-16">OR</div></v-col
                                                  >
                                                  <v-col
                                                    cols="5"
                                                    class="dialog-table px-2 mx-2 mb-4"
                                                  >
                                                    <v-card-title>Fetch Image</v-card-title>
                                                    <v-text-field
                                                      label="folder name"
                                                      v-model="folderName"
                                                    ></v-text-field>
                                                    <v-text-field
                                                      label="file name"
                                                      v-model="fileName"
                                                    ></v-text-field>
                                                    <v-btn
                                                      color="green darken-1"
                                                      @click="
                                                        fetchImage(
                                                          layer,
                                                          toggle,
                                                          'Normal Front',
                                                          baseType,
                                                          'image'
                                                        )
                                                      "
                                                      class="mb-4 mt-4 float-right"
                                                    >
                                                      Fetch
                                                    </v-btn>
                                                  </v-col>
                                                </v-row>
                                              </v-card>
                                            </v-dialog>
                                          </v-row>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-3">
                                      <div class="form-group">
                                        <label
                                          for="layer-sequence"
                                          class="font-9 poppins-bold toggle-name-text"
                                          >{{ `${toggle.name}-MB-D${baseIndex + 1}` }}</label
                                        >
                                        <div>
                                          <button
                                            type="button"
                                            class="btn btn-outline-primary btn-sm font-8"
                                            @click="
                                              normalBack = true;
                                              selectedBaseId = baseType.id;
                                            "
                                          >
                                            Choose Image
                                          </button>
                                          <div class="image-preview mt-3 mb-8">
                                            <v-card
                                              height="150"
                                              v-if="
                                                baseType &&
                                                baseType.toggleImageBody &&
                                                baseType.toggleImageBody.length > 0 &&
                                                baseType.toggleImageBody[1] &&
                                                baseType.toggleImageBody[1].image &&
                                                baseType.toggleImageBody[1].image
                                              "
                                            >
                                              <img
                                                @click="baseType.toggleImageBody[1].image = ''"
                                                src="@/assets/Images/remove.svg"
                                                alt="logo"
                                                class="pl-14"
                                              />
                                              <v-img
                                                :lazy-src="baseType.toggleImageBody[1].image"
                                                :src="baseType.toggleImageBody[1].image"
                                              ></v-img>
                                            </v-card>
                                          </div>
                                          <v-row justify="center">
                                            <v-dialog v-model="normalBack" max-width="700">
                                              <v-card>
                                                <div class="text-center font-22 mb-4 pt-4">
                                                  Upload Image
                                                  <div
                                                    class="
                                                      font-13
                                                      black--text
                                                      amber
                                                      darken-5
                                                      font-weight-bold
                                                      size-info
                                                      px-1
                                                    "
                                                  >
                                                    S-M ( 283 W &#10005; 452 H)
                                                  </div>
                                                  <div
                                                    class="
                                                      font-13
                                                      black--text
                                                      amber
                                                      darken-5
                                                      font-weight-bold
                                                      size-info
                                                      px-1
                                                    "
                                                  >
                                                    L-XL ( 336 W &#10005; 452 H)
                                                  </div>
                                                </div>
                                                <v-row no-gutters>
                                                  <v-col
                                                    cols="5"
                                                    class="dialog-table mx-4 mb-4 px-2"
                                                  >
                                                    <v-card-title>Choose from Device</v-card-title>
                                                    <input
                                                      type="file"
                                                      class="form-control mt-4"
                                                      id="file"
                                                      @change="
                                                        imagePickerClick(
                                                          $event,
                                                          layer,
                                                          toggle,
                                                          baseType,
                                                          'Normal Back',
                                                          'image'
                                                        )
                                                      "
                                                      :key="componentFrontKey"
                                                      accept="image/*"
                                                    />
                                                  </v-col>
                                                  <v-col cols="1"
                                                    ><div class="text-center mt-16">OR</div></v-col
                                                  >
                                                  <v-col
                                                    cols="5"
                                                    class="dialog-table mb-4 mx-2 px-2"
                                                  >
                                                    <v-card-title>Fetch Image</v-card-title>
                                                    <v-text-field
                                                      label="folder Name"
                                                      v-model="folderName"
                                                    ></v-text-field>
                                                    <v-text-field
                                                      label="file name"
                                                      v-model="fileName"
                                                    ></v-text-field>
                                                    <v-btn
                                                      color="green darken-1"
                                                      @click="
                                                        fetchImage(
                                                          layer,
                                                          toggle,
                                                          'Normal Back',
                                                          baseType,
                                                          'image'
                                                        )
                                                      "
                                                      class="mb-4 mt-4 float-right"
                                                    >
                                                      Fetch
                                                    </v-btn>
                                                  </v-col>
                                                </v-row>
                                              </v-card>
                                            </v-dialog>
                                          </v-row>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-3">
                                      <div class="form-group">
                                        <label
                                          for="layer-sequence"
                                          class="font-9 poppins-bold toggle-name-text"
                                          >{{ `${toggle.name}-PF-D${baseIndex + 1}` }}</label
                                        >
                                        <div>
                                          <button
                                            type="button"
                                            class="btn btn-outline-primary btn-sm font-8"
                                            @click="
                                              plusFront = true;
                                              selectedBaseId = baseType.id;
                                            "
                                          >
                                            Choose Image
                                          </button>
                                          <div class="image-preview mt-3 mb-8">
                                            <v-card
                                              height="150"
                                              v-if="
                                                baseType &&
                                                baseType.toggleImageBody &&
                                                baseType.toggleImageBody.length > 0 &&
                                                baseType.toggleImageBody[2] &&
                                                baseType.toggleImageBody[2].image &&
                                                baseType.toggleImageBody[2].image
                                              "
                                            >
                                              <img
                                                @click="baseType.toggleImageBody[2].image = ''"
                                                src="@/assets/Images/remove.svg"
                                                alt="logo"
                                                class="pl-14"
                                              />
                                              <v-img
                                                :lazy-src="baseType.toggleImageBody[2].image"
                                                :src="baseType.toggleImageBody[2].image"
                                              ></v-img>
                                            </v-card>
                                          </div>
                                          <v-row justify="center">
                                            <v-dialog v-model="plusFront" max-width="700">
                                              <v-card>
                                                <div class="text-center font-22 mb-4 pt-4">
                                                  Upload Image
                                                  <div
                                                    class="
                                                      font-13
                                                      black--text
                                                      amber
                                                      darken-5
                                                      font-weight-bold
                                                      size-info
                                                      px-1
                                                    "
                                                  >
                                                    S-M ( 283 W &#10005; 452 H)
                                                  </div>
                                                  <div
                                                    class="
                                                      font-13
                                                      black--text
                                                      amber
                                                      darken-5
                                                      font-weight-bold
                                                      size-info
                                                      px-1
                                                    "
                                                  >
                                                    L-XL ( 336 W &#10005; 452 H)
                                                  </div>
                                                </div>
                                                <v-row no-gutters>
                                                  <v-col
                                                    cols="5"
                                                    class="dialog-table mx-4 mb-4 px-2"
                                                  >
                                                    <v-card-title>Choose from Device</v-card-title>
                                                    <input
                                                      type="file"
                                                      class="form-control mt-4"
                                                      id="file"
                                                      @change="
                                                        imagePickerClick(
                                                          $event,
                                                          layer,
                                                          toggle,
                                                          baseType,
                                                          'Plus Front',
                                                          'image'
                                                        )
                                                      "
                                                      :key="componentFrontKey"
                                                      accept="image/*"
                                                    />
                                                  </v-col>
                                                  <v-col cols="1"
                                                    ><div class="text-center mt-16">OR</div></v-col
                                                  >
                                                  <v-col
                                                    cols="5"
                                                    class="dialog-table mx-2 px-2 mb-4"
                                                  >
                                                    <v-card-title>Fetch Image</v-card-title>
                                                    <v-text-field
                                                      label="folder Name"
                                                      v-model="folderName"
                                                    ></v-text-field>
                                                    <v-text-field
                                                      label="file name"
                                                      v-model="fileName"
                                                    ></v-text-field>
                                                    <v-btn
                                                      color="green darken-1"
                                                      @click="
                                                        fetchImage(
                                                          layer,
                                                          toggle,
                                                          'Plus Front',
                                                          baseType,
                                                          'image'
                                                        )
                                                      "
                                                      class="mb-4 mt-4 float-right"
                                                    >
                                                      Fetch
                                                    </v-btn>
                                                  </v-col>
                                                </v-row>
                                              </v-card>
                                            </v-dialog>
                                          </v-row>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-3">
                                      <div class="form-group">
                                        <label
                                          for="layer-sequence"
                                          class="font-9 poppins-bold toggle-name-text"
                                          >{{ `${toggle.name}-PB-D${baseIndex + 1}` }}</label
                                        >
                                        <div>
                                          <button
                                            type="button"
                                            class="btn btn-outline-primary btn-sm font-8"
                                            @click="
                                              plusBack = true;
                                              selectedBaseId = baseType.id;
                                            "
                                          >
                                            Choose Image
                                          </button>
                                          <div class="image-preview mt-3 mb-8">
                                            <v-card
                                              height="150"
                                              v-if="
                                                baseType &&
                                                baseType.toggleImageBody &&
                                                baseType.toggleImageBody.length > 0 &&
                                                baseType.toggleImageBody[3] &&
                                                baseType.toggleImageBody[3].image &&
                                                baseType.toggleImageBody[3].image
                                              "
                                            >
                                              <img
                                                @click="baseType.toggleImageBody[3].image = ''"
                                                src="@/assets/Images/remove.svg"
                                                alt="logo"
                                                class="pl-14"
                                              />
                                              <v-img
                                                :lazy-src="baseType.toggleImageBody[3].image"
                                                :src="baseType.toggleImageBody[3].image"
                                              ></v-img>
                                            </v-card>
                                          </div>
                                          <v-row justify="center">
                                            <v-dialog v-model="plusBack" max-width="700">
                                              <v-card>
                                                <div class="text-center font-22 mb-4 pt-4">
                                                  Upload Image
                                                  <div
                                                    class="
                                                      font-13
                                                      black--text
                                                      amber
                                                      darken-5
                                                      font-weight-bold
                                                      size-info
                                                      px-1
                                                    "
                                                  >
                                                    S-M ( 283 W &#10005; 452 H)
                                                  </div>
                                                  <div
                                                    class="
                                                      font-13
                                                      black--text
                                                      amber
                                                      darken-5
                                                      font-weight-bold
                                                      size-info
                                                      px-1
                                                    "
                                                  >
                                                    L-XL ( 336 W &#10005; 452 H)
                                                  </div>
                                                </div>
                                                <v-row no-gutters>
                                                  <v-col
                                                    cols="5"
                                                    class="dialog-table mx-4 mb-4 px-2"
                                                  >
                                                    <v-card-title>Choose from Device</v-card-title>
                                                    <input
                                                      type="file"
                                                      class="form-control mt-4"
                                                      id="file"
                                                      @change="
                                                        imagePickerClick(
                                                          $event,
                                                          layer,
                                                          toggle,
                                                          baseType,
                                                          'Plus Back',
                                                          'image'
                                                        )
                                                      "
                                                      :key="componentFrontKey"
                                                      accept="image/*"
                                                    />
                                                  </v-col>
                                                  <v-col cols="1"
                                                    ><div class="text-center mt-16">OR</div></v-col
                                                  >
                                                  <v-col
                                                    cols="5"
                                                    class="dialog-table mx-2 px-2 mb-4"
                                                  >
                                                    <v-card-title>Fetch Image</v-card-title>
                                                    <v-text-field
                                                      label="folder Name"
                                                      v-model="folderName"
                                                    ></v-text-field>
                                                    <v-text-field
                                                      label="file name"
                                                      v-model="fileName"
                                                    ></v-text-field>
                                                    <v-btn
                                                      color="green darken-1"
                                                      @click="
                                                        fetchImage(
                                                          layer,
                                                          toggle,
                                                          'Plus Back',
                                                          baseType,
                                                          'image'
                                                        )
                                                      "
                                                      class="mb-4 mt-4 float-right"
                                                    >
                                                      Fetch
                                                    </v-btn>
                                                  </v-col>
                                                </v-row>
                                              </v-card>
                                            </v-dialog>
                                          </v-row>
                                        </div>
                                        <!-- <div>
                                     
                                    </div> -->
                                      </div>
                                    </div>
                                  </div>
                                  <div class="padding-10"></div>
                                  <div class="layer-section" v-if="baseType.showEmbroidery">
                                    <h6>Select Embroidery Images</h6>
                                    <div
                                      class="row"
                                      v-for="(embroidery, index) in baseType.embroidery"
                                      v-bind:key="index"
                                    >
                                      <div class="col-md-3 pb-10">
                                        <div class="form-group">
                                          <label
                                            for="layer-sequence"
                                            class="font-9 poppins-bold toggle-name-text"
                                            >{{
                                              `${toggle.name}-MF-D${baseIndex + 1}-E${index + 1}`
                                            }}</label
                                          >
                                          <div>
                                            <button
                                              type="button"
                                              class="btn btn-outline-primary btn-sm font-8"
                                              @click="
                                                normalFrontEmb = true;
                                                selectedEmbName = embroidery.name;
                                              "
                                            >
                                              Choose Image -
                                              {{ findEmbToggleNameUsingId(embroidery.id) }}
                                            </button>
                                            <div class="image-preview mt-3 mb-8">
                                              <v-card
                                                height="150"
                                                v-if="
                                                  embroidery &&
                                                  embroidery.images &&
                                                  embroidery.images.length > 0 &&
                                                  embroidery.images[0] &&
                                                  embroidery.images[0].image &&
                                                  embroidery.images[0].image
                                                "
                                              >
                                                <img
                                                  @click="embroidery.images[0].image = ''"
                                                  src="@/assets/Images/remove.svg"
                                                  alt="logo"
                                                  class="pl-14"
                                                />

                                                <v-img
                                                  :lazy-src="embroidery.images[0].image"
                                                  :src="embroidery.images[0].image"
                                                ></v-img>
                                              </v-card>
                                            </div>
                                            <v-row justify="center">
                                              <v-dialog v-model="normalFrontEmb" max-width="700">
                                                <v-card>
                                                  <div class="text-center font-22 mb-4 pt-4">
                                                    Upload Image
                                                    <div
                                                      class="
                                                        font-13
                                                        black--text
                                                        amber
                                                        darken-5
                                                        font-weight-bold
                                                        size-info
                                                        px-1
                                                      "
                                                    >
                                                      S-M ( 283 W &#10005; 452 H)
                                                    </div>
                                                    <div
                                                      class="
                                                        font-13
                                                        black--text
                                                        amber
                                                        darken-5
                                                        font-weight-bold
                                                        size-info
                                                        px-1
                                                      "
                                                    >
                                                      L-XL ( 336 W &#10005; 452 H)
                                                    </div>
                                                  </div>
                                                  <v-row no-gutters>
                                                    <v-col
                                                      cols="5"
                                                      class="dialog-table px-2 mx-4 mb-4"
                                                    >
                                                      <v-card-title
                                                        >Choose from Device
                                                      </v-card-title>
                                                      <input
                                                        type="file"
                                                        class="form-control"
                                                        id="file"
                                                        @input="
                                                          embImagePickerClick(
                                                            $event,
                                                            layer,
                                                            toggle,
                                                            baseType,
                                                            'Normal Front',
                                                            embroidery.name,
                                                            baseIndex
                                                          )
                                                        "
                                                        :key="componentFrontKeyEmb"
                                                        accept="image/*"
                                                      />
                                                    </v-col>
                                                    <v-col cols="1"
                                                      ><div class="text-center mt-16">
                                                        OR
                                                      </div></v-col
                                                    >
                                                    <v-col
                                                      cols="5"
                                                      class="dialog-table px-2 mx-2 mb-4"
                                                    >
                                                      <v-card-title>Fetch Image</v-card-title>
                                                      <v-text-field
                                                        label="folder Name"
                                                        v-model="folderName"
                                                      ></v-text-field>
                                                      <v-text-field
                                                        label="file name"
                                                        v-model="fileName"
                                                      ></v-text-field>
                                                      <v-btn
                                                        color="green darken-1"
                                                        @click="
                                                          fetchEmbImage(
                                                            layer,
                                                            toggle,
                                                            'Normal Front',
                                                            embroidery.name,
                                                            baseType,
                                                            baseIndex
                                                          )
                                                        "
                                                        class="mb-4 mt-4 float-right"
                                                      >
                                                        Fetch
                                                      </v-btn>
                                                    </v-col>
                                                  </v-row>
                                                </v-card>
                                              </v-dialog>
                                            </v-row>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-3 pb-4">
                                        <div class="form-group">
                                          <label
                                            for="layer-sequence"
                                            class="font-9 poppins-bold toggle-name-text"
                                            >{{
                                              `${toggle.name}-MB-D${baseIndex + 1}-E${index + 1}`
                                            }}</label
                                          >
                                          <div>
                                            <button
                                              type="button"
                                              class="btn btn-outline-primary btn-sm font-8"
                                              @click="
                                                normalBackEmb = true;
                                                selectedEmbName = embroidery.name;
                                              "
                                            >
                                              Choose Image - {{ embroidery.name }}
                                            </button>
                                            <div class="image-preview mt-3 mb-8">
                                              <v-card
                                                height="150"
                                                v-if="
                                                  embroidery &&
                                                  embroidery.images &&
                                                  embroidery.images.length > 0 &&
                                                  embroidery.images[1] &&
                                                  embroidery.images[1].image &&
                                                  embroidery.images[1].image
                                                "
                                              >
                                                <img
                                                  @click="embroidery.images[1].image = ''"
                                                  src="@/assets/Images/remove.svg"
                                                  alt="logo"
                                                  class="pl-14"
                                                />
                                                <v-img
                                                  :lazy-src="embroidery.images[1].image"
                                                  :src="embroidery.images[1].image"
                                                ></v-img>
                                              </v-card>
                                            </div>
                                            <v-row justify="center">
                                              <v-dialog v-model="normalBackEmb" max-width="700">
                                                <v-card>
                                                  <div class="text-center font-22 mb-4 pt-4">
                                                    Upload Image
                                                    <div
                                                      class="
                                                        font-13
                                                        black--text
                                                        amber
                                                        darken-5
                                                        font-weight-bold
                                                        size-info
                                                        px-1
                                                      "
                                                    >
                                                      S-M ( 283 W &#10005; 452 H)
                                                    </div>
                                                    <div
                                                      class="
                                                        font-13
                                                        black--text
                                                        amber
                                                        darken-5
                                                        font-weight-bold
                                                        size-info
                                                        px-1
                                                      "
                                                    >
                                                      L-XL ( 336 W &#10005; 452 H)
                                                    </div>
                                                  </div>
                                                  <v-row no-gutters>
                                                    <v-col
                                                      cols="5"
                                                      class="dialog-table mx-4 mb-4 px-2"
                                                    >
                                                      <v-card-title
                                                        >Choose from Device</v-card-title
                                                      >
                                                      <input
                                                        type="file"
                                                        class="form-control"
                                                        id="file"
                                                        @change="
                                                          embImagePickerClick(
                                                            $event,
                                                            layer,
                                                            toggle,
                                                            baseType,
                                                            'Normal Back',
                                                            embroidery.name,
                                                            baseIndex
                                                          )
                                                        "
                                                        :key="componentFrontKeyEmb"
                                                        accept="image/*"
                                                      />
                                                    </v-col>
                                                    <v-col cols="1"
                                                      ><div class="text-center mt-16">
                                                        OR
                                                      </div></v-col
                                                    >
                                                    <v-col
                                                      cols="5"
                                                      class="dialog-table mb-4 mx-2 px-2"
                                                    >
                                                      <v-card-title>Fetch Image</v-card-title>
                                                      <v-text-field
                                                        label="folder Name"
                                                        v-model="folderName"
                                                      ></v-text-field>
                                                      <v-text-field
                                                        label="file name"
                                                        v-model="fileName"
                                                      ></v-text-field>
                                                      <v-btn
                                                        color="green darken-1"
                                                        @click="
                                                          fetchEmbImage(
                                                            layer,
                                                            toggle,
                                                            'Normal Back',
                                                            embroidery.name,
                                                            baseType,
                                                            baseIndex
                                                          )
                                                        "
                                                        class="mb-4 mt-4 float-right"
                                                      >
                                                        Fetch
                                                      </v-btn>
                                                    </v-col>
                                                  </v-row>
                                                </v-card>
                                              </v-dialog>
                                            </v-row>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-3 pb-4">
                                        <div class="form-group">
                                          <label
                                            for="layer-sequence"
                                            class="font-9 poppins-bold toggle-name-text"
                                            >{{
                                              `${toggle.name}-PF-D${baseIndex + 1}-E${index + 1}`
                                            }}</label
                                          >
                                          <div>
                                            <button
                                              type="button"
                                              class="btn btn-outline-primary btn-sm font-8"
                                              @click="
                                                plusFrontEmb = true;
                                                selectedEmbName = embroidery.name;
                                              "
                                            >
                                              Choose Image - {{ embroidery.name }}
                                            </button>
                                            <div class="image-preview mt-3 mb-8">
                                              <v-card
                                                height="150"
                                                v-if="
                                                  embroidery &&
                                                  embroidery.images &&
                                                  embroidery.images.length > 0 &&
                                                  embroidery.images[2] &&
                                                  embroidery.images[2].image &&
                                                  embroidery.images[2].image
                                                "
                                              >
                                                <img
                                                  @click="embroidery.images[2].image = ''"
                                                  src="@/assets/Images/remove.svg"
                                                  alt="logo"
                                                  class="pl-14"
                                                />
                                                <v-img
                                                  :lazy-src="embroidery.images[2].image"
                                                  :src="embroidery.images[2].image"
                                                ></v-img>
                                              </v-card>
                                            </div>
                                            <v-row justify="center">
                                              <v-dialog v-model="plusFrontEmb" max-width="700">
                                                <v-card>
                                                  <div class="text-center font-22 mb-4 pt-4">
                                                    Upload Image
                                                    <div
                                                      class="
                                                        font-13
                                                        black--text
                                                        amber
                                                        darken-5
                                                        font-weight-bold
                                                        size-info
                                                        px-1
                                                      "
                                                    >
                                                      S-M ( 283 W &#10005; 452 H)
                                                    </div>
                                                    <div
                                                      class="
                                                        font-13
                                                        black--text
                                                        amber
                                                        darken-5
                                                        font-weight-bold
                                                        size-info
                                                        px-1
                                                      "
                                                    >
                                                      L-XL ( 336 W &#10005; 452 H)
                                                    </div>
                                                  </div>
                                                  <v-row no-gutters>
                                                    <v-col
                                                      cols="5"
                                                      class="dialog-table mx-4 mb-4 px-2"
                                                    >
                                                      <v-card-title
                                                        >Choose from Device</v-card-title
                                                      >

                                                      <input
                                                        type="file"
                                                        class="form-control"
                                                        id="file"
                                                        @change="
                                                          embImagePickerClick(
                                                            $event,
                                                            layer,
                                                            toggle,
                                                            baseType,
                                                            'Plus Front',
                                                            embroidery.name,
                                                            baseIndex
                                                          )
                                                        "
                                                        :key="componentFrontKeyEmb"
                                                        accept="image/*"
                                                      />
                                                    </v-col>
                                                    <v-col cols="1"
                                                      ><div class="text-center mt-16">
                                                        OR
                                                      </div></v-col
                                                    >
                                                    <v-col
                                                      cols="5"
                                                      class="dialog-table mx-2 px-2 mb-4"
                                                    >
                                                      <v-card-title>Fetch Image</v-card-title>
                                                      <v-text-field
                                                        label="folder Name"
                                                        v-model="folderName"
                                                      ></v-text-field>
                                                      <v-text-field
                                                        label="file name"
                                                        v-model="fileName"
                                                      ></v-text-field>
                                                      <v-btn
                                                        color="green darken-1"
                                                        @click="
                                                          fetchEmbImage(
                                                            layer,
                                                            toggle,
                                                            'Plus Front',
                                                            embroidery.name,
                                                            baseType,
                                                            baseIndex
                                                          )
                                                        "
                                                        class="mb-4 mt-4 float-right"
                                                      >
                                                        Fetch
                                                      </v-btn>
                                                    </v-col>
                                                  </v-row>
                                                </v-card>
                                              </v-dialog>
                                            </v-row>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-3 pb-4">
                                        <div class="form-group">
                                          <label
                                            for="layer-sequence"
                                            class="font-9 poppins-bold toggle-name-text"
                                            >{{
                                              `${toggle.name}-PB-D${baseIndex + 1}-E${index + 1}`
                                            }}</label
                                          >
                                          <div>
                                            <button
                                              type="button"
                                              class="btn btn-outline-primary btn-sm font-8"
                                              @click="
                                                plusBackEmb = true;
                                                selectedEmbName = embroidery.name;
                                              "
                                            >
                                              Choose Image - {{ embroidery.name }}
                                            </button>
                                            <div class="image-preview mt-3 mb-8">
                                              <v-card
                                                height="150"
                                                v-if="
                                                  embroidery &&
                                                  embroidery.images &&
                                                  embroidery.images.length > 0 &&
                                                  embroidery.images[3] &&
                                                  embroidery.images[3].image &&
                                                  embroidery.images[3].image
                                                "
                                              >
                                                <img
                                                  @click="embroidery.images[3].image = ''"
                                                  src="@/assets/Images/remove.svg"
                                                  alt="logo"
                                                  class="pl-14"
                                                />
                                                <v-img
                                                  :lazy-src="embroidery.images[3].image"
                                                  :src="embroidery.images[3].image"
                                                ></v-img>
                                              </v-card>
                                            </div>
                                            <v-row justify="center">
                                              <v-dialog v-model="plusBackEmb" max-width="700">
                                                <v-card>
                                                  <div class="text-center font-22 mb-4 pt-4">
                                                    Upload Image
                                                    <div
                                                      class="
                                                        font-13
                                                        black--text
                                                        amber
                                                        darken-5
                                                        font-weight-bold
                                                        size-info
                                                        px-1
                                                      "
                                                    >
                                                      S-M ( 283 W &#10005; 452 H)
                                                    </div>
                                                    <div
                                                      class="
                                                        font-13
                                                        black--text
                                                        amber
                                                        darken-5
                                                        font-weight-bold
                                                        size-info
                                                        px-1
                                                      "
                                                    >
                                                      L-XL ( 336 W &#10005; 452 H)
                                                    </div>
                                                  </div>
                                                  <v-row no-gutters>
                                                    <v-col
                                                      cols="5"
                                                      class="dialog-table mx-4 mb-4 px-2"
                                                    >
                                                      <v-card-title
                                                        >Choose from Device</v-card-title
                                                      >
                                                      <input
                                                        type="file"
                                                        class="form-control"
                                                        id="file"
                                                        @change="
                                                          embImagePickerClick(
                                                            $event,
                                                            layer,
                                                            toggle,
                                                            baseType,
                                                            'Plus Back',
                                                            embroidery.name,
                                                            baseIndex
                                                          )
                                                        "
                                                        :key="componentFrontKeyEmb"
                                                        accept="image/*"
                                                      />
                                                    </v-col>
                                                    <v-col cols="1"
                                                      ><div class="text-center mt-16">
                                                        OR
                                                      </div></v-col
                                                    >
                                                    <v-col
                                                      cols="5"
                                                      class="dialog-table mx-2 px-2 mb-4"
                                                    >
                                                      <v-card-title>Fetch Image</v-card-title>
                                                      <v-text-field
                                                        label="folder Name"
                                                        v-model="folderName"
                                                      ></v-text-field>
                                                      <v-text-field
                                                        label="file name"
                                                        v-model="fileName"
                                                      ></v-text-field>
                                                      <v-btn
                                                        color="green darken-1"
                                                        @click="
                                                          fetchEmbImage(
                                                            layer,
                                                            toggle,
                                                            'Plus Back',
                                                            embroidery.name,
                                                            baseType,
                                                            baseIndex
                                                          )
                                                        "
                                                        class="mb-4 mt-4 float-right"
                                                      >
                                                        Fetch
                                                      </v-btn>
                                                    </v-col>
                                                  </v-row>
                                                </v-card>
                                              </v-dialog>
                                            </v-row>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <!-- <div
                                  class="row"
                                  v-for="(
                                    embroidery, index
                                  ) in toggle.embroidery"
                                  v-bind:key="index"
                                >
                                  <h6>{{ embroidery.name }}</h6>
                                  <div class="col-md-3">
                                    <div class="form-group">
                                      <label for="layer-sequence"
                                        >Normal Front </label
                                      >
                                      <div>
                                        <input
                                          type="file"
                                          class="form-control"
                                          id="file"
                                          @change="
                                            embImagePickerClick(
                                              $event,
                                              layer,
                                              toggle,
                                              'Normal Front',
                                              embroidery.name
                                            )
                                          "
                                          :key="componentFrontKey"
                                          accept="image/*"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-3">
                                    <div class="form-group">
                                      <label for="layer-sequence"
                                        >Normal Back</label
                                      >
                                      <div>
                                        <input
                                          type="file"
                                          class="form-control"
                                          id="file"
                                          @change="
                                            embImagePickerClick(
                                              $event,
                                              layer,
                                              toggle,
                                              'Normal Back',
                                              embroidery.name
                                            )
                                          "
                                          :key="componentFrontKey"
                                          accept="image/*"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-3">
                                    <div class="form-group">
                                      <label for="layer-sequence"
                                        >Plus Front</label
                                      >
                                      <div>
                                        <input
                                          type="file"
                                          class="form-control"
                                          id="file"
                                          @change="
                                            embImagePickerClick(
                                              $event,
                                              layer,
                                              toggle,
                                              'Plus Front',
                                              embroidery.name
                                            )
                                          "
                                          :key="componentFrontKey"
                                          accept="image/*"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-3">
                                    <div class="form-group">
                                      <label for="layer-sequence"
                                        >Plus Back</label
                                      >
                                      <div>
                                        <input
                                          type="file"
                                          class="form-control"
                                          id="file"
                                          @change="
                                            embImagePickerClick(
                                              $event,
                                              layer,
                                              toggle,
                                              'Plus Back',
                                              embroidery.name
                                            )
                                          "
                                          :key="componentFrontKey"
                                          accept="image/*"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="padding-10"></div>
                                </div> -->
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div v-if="customizationsLoaded" class="col-6 model-fixed">
            <CustomizationModel :show="showFlag" />
          </div>
        </div>
        <div class="d-flex">
          <div class="next-button-section mr-4">
            <div @click="updateData('Save')" class="btn btn-success btn-lg btn-block">Save</div>
          </div>
          <!-- <div class="next-button-section">
              <div @click="updateData('Next')" class="btn btn-success btn-lg btn-block">Next</div>
            </div> -->
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import _ from 'lodash';
import service from '../service/apiService';
import CustomizationModel from '@/components/customization/CustomizationModel.vue';
import customizationService from '../services/customizationService';
import productService from '@/services/productService.js';
import remove from '@/assets/Images/remove.svg';
import { v4 as uuid_v4 } from 'uuid';
import imageUploadService from '../../src/services/imageUploadService.js';


// @ is an alias to /src

export default {
  name: 'Home',
  components: { CustomizationModel },

  data() {
    return {
      colorObj: {},
      embColorObj: {},
      customizationsLoaded: false,
      buttonHide: true,
      overlay: false,
      selectedSize: 'Normal',
      direction: 'Front',
      selectedCategory: 0,
      customization: {},
      colorSelection: '',
      embroiderySelection: '',
      productData: {},
      selectedToggle: 0,
      selectedToggleImageSide: '',
      selectedEmbName: '',
      selectedBaseId: '',
      selectedLayer: 0,
      componentFrontKey: 0,
      componentFrontKeyEmb: 0,

      imgixConfig: '?auto=compress&w=400',
      imgPickerForEmb: false,
      categories: [],
      gender: ['Male', 'Female'],
      embroideries: [],
      bodytypes: ['Front', 'Back', 'Plus Front', 'Plus Back'],
      imageTypes: ['Core', 'Shading'],
      colors: ['Color 1', 'Color 2', 'Color 3', 'Color 4', 'Color 5'],
      folderId: '',
      folderName: '',
      BulkfolderName: '',
      fileName: '',
      normalFront: false,
      normalBack: false,
      plusFront: false,
      plusBack: false,

      normalFrontEmb: false,
      normalBackEmb: false,
      plusFrontEmb: false,
      plusBackEmb: false,

      boxImageUrl: '',
      showFlag: true,
      selectedStatus: 'draft',
      allEmbArray: [],
      fetching: false,
      boxImages: [],
      unmatchedImageNames: [],
      name: '',
      query: [],
      customizationFirstData: {},
    };
  },
  created() {
    this.getData();
  },
  methods: {
    findEmbToggleNameUsingId(embToggleId) {
      var name = '';
      _.each(this.categories, (category) => {
        _.each(category.layers, (layer) => {
          if (layer.typeOfLayer == 'Embroidery') {
            _.each(layer.toggles, (toggle) => {
              if (toggle.id === embToggleId) {
                name = toggle.name;
              }
            });
          }
        });
      });
      return name;
    },

    toggleToggleShow(layer, toggle, category) {
      const finalLayer = _.find(layer.toggles, (n) => {
        const obj = n === toggle;
        return obj;
      });
      finalLayer.show = !finalLayer.show;
      /* eslint-disable */
      this.checkToShowEmbImagOption(category);
      this.$forceUpdate();
    },
    toggleEmbroidery(layer, toggle, category, baseType, baseIndex) {
      const finalLayer = _.find(layer.toggles, (n) => {
        const obj = n === toggle;
        return obj;
      });
      // finalLayer.showEmbroidery = !finalLayer.showEmbroidery;
      finalLayer.baseTypes[baseIndex].showEmbroidery =
        !finalLayer.baseTypes[baseIndex].showEmbroidery;

      this.createEmbImageArray(layer, toggle, category, baseType, baseIndex);
      this.$forceUpdate();
    },
    async updateData(title) {
      this.productData.categories = this.categories;
      this.productData.customizationStatus = this.selectedStatus;
      this.productData.gender = this.gender;
      this.productData.name = this.name;
      this.productData.sku = this.query;
      console.log('product::::::', this.productData);
      const result = await service.updateReplacement(this.$route.params.id, this.productData);
      if (result.status === 200) {
        /* eslint-disable */
        if (title == 'Next') {
          await this.$toasted.show('data saved!.', {
            theme: 'bubble',
            position: 'bottom-center',
            duration: 500,
          });
          await this.$router.push({
            name: 'Step3',
            params: { id: result.data._id },
          });
        } else {
          await this.$toasted.show('data saved!.', {
            theme: 'bubble',
            position: 'bottom-center',
            duration: 500,
          });
        }

        this.categories = result.data.categories;
        this.name = result.data.name;
        this.gender = result.data.gender;
        this.query = result.data.sku;
        this.productData = result.data;
      }
    },
    imagePickerClick(event, layer, toggle, baseType, side) {
      this.componentFrontKey += 1;
      imageUploadService.uploadImage(event.target.files[0], (result) => {
        if (result.status === 200) {
          this.$toasted.show('Image added!.', {
            theme: 'bubble',
            position: 'bottom-center',
            duration: 1000,
          });
          switch (side) {
            case 'Normal Front':
              this.normalFront = false;
              break;
            case 'Normal Back':
              this.normalBack = false;
              break;
            case 'Plus Front':
              this.plusFront = false;
              break;
            case 'Plus Back':
              this.plusBack = false;
              break;
          }
          const url = result.data.meta.location + this.imgixConfig;

          _.each(this.categories, (category) => {
            _.each(category.layers, (layer) => {
              _.each(layer.toggles, (toggle) => {
                _.each(toggle.baseTypes, (baseType) => {
                  if (baseType.id == this.selectedBaseId) {
                    const filterArray = _.filter(baseType.toggleImageBody, {
                      name: side,
                    });
                    filterArray[0].image = url;
                  }
                });
              });
            });
          });

          /* eslint-disable */

          this.showFlag = false;
          this.showFlag = true;
        }
      });
    },
    embImagePickerClick(event, layer, toggle, baseType, side, name, baseIndex) {
      switch (side) {
        case 'Normal Front':
          this.normalFrontEmb = false;
          break;
        case 'Normal Back':
          this.normalBackEmb = false;
          break;
        case 'Plus Front':
          this.plusFrontEmb = false;
          break;
        case 'Plus Back':
          this.plusBackEmb = false;
          break;
      }
      this.componentFrontKeyEmb = this.componentFrontKeyEmb + 1;
      console.log('this.componentFrontKeyEmb', this.componentFrontKeyEmb);
      imageUploadService.uploadImage(event.target.files[0], (result) => {
        if (result.status === 200) {
          this.$toasted.show('Image added!.', {
            theme: 'bubble',
            position: 'bottom-center',
            duration: 1000,
          });
          const url = result.data.meta.location + this.imgixConfig;
          /* eslint-disable */
          const filterArray = _.filter(toggle.baseTypes[baseIndex].embroidery, {
            name: this.selectedEmbName,
          });
          const filterEmbArray = _.filter(filterArray[0].images, {
            name: side,
          });
          filterEmbArray[0].image = url;
          this.showFlag = false;
          this.showFlag = true;
        }
      });
    },
    createEmbImageArray(layer, toggle, category, baseType, baseIndex) {
      console.log('baseIndex', baseIndex);
      let finalToggles = [];
      _.each(this.categories, (category) => {
        _.each(category.layers, (layer) => {
          if (layer.typeOfLayer == 'Embroidery') {
            _.each(layer.embroideryMapping, (embMapObj) => {
              if (category.id == embMapObj.categoryId) {
                finalToggles = layer.toggles;
              }
            });
          }
        });
      });
      var embArray = [];
      if (_.isEmpty(toggle.baseTypes[baseIndex].embroidery)) {
        // first time array creation
        embArray = [];
        _.map(finalToggles, (toggle) => {
          var temp = {};
          temp.name = toggle.name;
          temp.id = toggle.id;
          temp.images = [
            { name: 'Normal Front', image: 'IMG VALUE' },
            { name: 'Normal Back', image: 'IMG VALUE' },
            { name: 'Plus Front', image: 'IMG VALUE' },
            { name: 'Plus Back', image: 'IMG VALUE' },
          ];
          embArray.push(temp);
        });
        toggle.baseTypes[baseIndex].embroidery = embArray;
      } else {
        // second time array creation

        // console.log('all emb toggles', finalToggles); // these are all emb toggles
        // console.log('toggle.baseTypes', toggle.baseTypes); //

        // if (finalToggles.length >= toggle.baseTypes.length) {
        //  _.each(baseType.embroidery, (emb) => {
        // const presentEmb = _.find(finalToggles, (n) => {
        //   // const obj = n.name === emb.name;
        //   const obj = n.id === emb.id;
        //   return obj;
        // });
        // when new emb added then this will call
        var embArray = toggle.baseTypes[baseIndex].embroidery;

        //console.log('before embArray', embArray);

        _.map(finalToggles, (toggle) => {
          const presentEmb = _.find(embArray, (n) => {
            // const obj = n.name === toggle.name;
            const obj = n.id === toggle.id;
            return obj;
          });
          //console.log('presentEmb', presentEmb);

          if (presentEmb === undefined) {
            var temp = {};
            temp.name = toggle.name;
            temp.id = toggle.id;
            temp.images = [
              { name: 'Normal Front', image: 'IMG VALUE' },
              { name: 'Normal Back', image: 'IMG VALUE' },
              { name: 'Plus Front', image: 'IMG VALUE' },
              { name: 'Plus Back', image: 'IMG VALUE' },
            ];
            embArray.push(temp);
          }
        });

        // console.log('after embArray', embArray);
        // baseType.embroidery = embArray;
        // });
        // }
      }
      this.$forceUpdate();
    },

    createEmbList() {
      _.each(this.categories, (category) => {
        _.each(category.layers, (layer) => {
          if (layer.typeOfLayer == 'Embroidery') {
            this.allEmbArray = layer.toggles;
          }
        });
      });
    },
    checkToShowEmbImagOption(cat) {
      console.log('Cat', cat);
      var flag = false;
      var allEmbMappingArray = [];
      _.each(this.categories, (category) => {
        _.each(category.layers, (layer) => {
          if (layer.typeOfLayer == 'Embroidery') {
            _.each(layer.embroideryMapping, (embMapObj) => {
              allEmbMappingArray.push(embMapObj);
            });
          }
        });
      });
      const assignedEmbCat = _.find(allEmbMappingArray, (n) => {
        const obj = n.categoryId == cat.id;
        return obj;
      });
      console.log('assignedEmbCat', assignedEmbCat);
      if (assignedEmbCat == undefined) {
        flag = false;
        cat.embroideryAssigned = false;
      } else {
        if (assignedEmbCat.flag) {
          cat.embroideryAssigned = true;
          flag = true;
        } else {
          cat.embroideryAssigned = false;
          flag = false;
        }
      }
      return flag;
    },
    async getData() {
      const result = await service.getOneReplacement(this.$route.params.id);
      console.log('result', result);
      let sequenceArray = [];
      if (result.data.customizationFirstData.categories.length > 0) {
        sequenceArray = await this.sequenceCategoriesArray(
          result.data.customizationFirstData.categories
        );
        result.data.customizationFirstData.categories = sequenceArray;
      }
      this.customization = result.data;
      this.customizationFirstData = result.data.customizationFirstData;
      this.selectedStatus = result.data.customizationStatus;
      if (result.data.customizationFirstData) {
        this.$store.commit('setCustomization', this.customizationFirstData);
      } else {
        this.$store.commit('setCustomization', this.customization);
      }
      this.customizationsLoaded = true;
      this.categories = result.data.categories;
      this.hideAllToggle();
      this.hideAllLayers();
      this.embroideries = result.data.embroideries;
      this.gender = result.data.gender;
      this.name = result.data.name;
      this.query = result.data.sku;
      this.createEmbList();
    },
    async sequenceCategoriesArray(categories) {
      _.each(categories, (category) => {
        category.layers = _.orderBy(category.layers, [, 'sequence'], ['desc', 'asc']);
        _.each(category.layers, (layer) => {
          layer.toggles = _.orderBy(layer.toggles, [, 'sequence'], ['desc', 'asc']);
        });
      });
      categories = _.orderBy(categories, [, 'sequence'], ['desc', 'asc']);
      console.log('mycategories', categories);
      return categories;
    },
    embroideryMappingClick(category, layer) {
      const oldValue = layer.show;
      _.each(category.layers, (n) => {
        const obj = n;
        obj.show = false;
      });
      const finalLayer = _.find(category.layers, (n) => {
        const obj = n === layer;
        return obj;
      });
      finalLayer.show = !oldValue;
      if (_.isEmpty(finalLayer.embroideryMapping)) {
        finalLayer.embroideryMapping = _.map(this.categories, (n) => {
          return {
            id: '',
            categoryId: n.id,
            name: n.name,
            flag: false,
          };
        });
      } else {
        // _.each(finalLayer.embroideryMapping, (embMap) => {
        //   const presentCategory = _.find(this.categories, (n) => {
        //     const obj = n.id === embMap.categoryId;
        //     return obj;
        //   });
        //   // if (presentCategory === undefined) {
        //   //   finalLayer.embroideryMapping.push({
        //   //     id: '',
        //   //     categoryId: n.id,
        //   //     name: n.name,
        //   //     flag: false,
        //   //   });
        //   // }
        // });

        _.each(this.categories, (category) => {
          const presentCategory = _.find(finalLayer.embroideryMapping, (embMap) => {
            const obj = category.id === embMap.categoryId;
            return obj;
          });

          if (presentCategory === undefined) {
            finalLayer.embroideryMapping.push({
              id: '',
              categoryId: category.id,
              name: category.name,
              flag: false,
            });
          }
        });
      }

      if (oldValue && !_.isEmpty(finalLayer.embroideryMapping)) {
        _.each(layer.toggles, (toggle) => {
          const obj = toggle;
          const uniqueColors = this.uniqueColors(finalLayer.embroideryMapping);
          obj.colorAssignment = _.map(uniqueColors, (uniqueColor) => {
            return {
              colorMappingId: uniqueColor,
              colorObject: {
                saturation: '0',
                hue: '0',
                brightness: '0',
                opacity: '100',
              },
            };
          });
        });
      }
    },
    hideAllToggle() {
      _.each(this.categories, (category) => {
        _.each(category.layers, (layer) => {
          _.each(layer.toggles, (toggle) => {
            _.each(toggle.baseTypes, (baseType) => {
              baseType.show = false;
              baseType.showEmbroidery = false;
            });
          });
        });
      });
    },
    hideAllLayers() {
      _.each(this.categories, (category) => {
        _.each(category.layers, (layer) => {
          layer.show = false;
        });
      });
    },
    findCategoryName(categoryId, categoryName) {
      const categoryObj = _.find(this.categories, (n) => {
        const obj = n.id === categoryId;
        return obj;
      });
      if (categoryObj && categoryObj.name) {
        return categoryObj.name;
      } else {
        return categoryName;
      }
    },
    changeEmbOption(e, embMapping) {
      console.log('embMapping', embMapping);
      if (e.target.value == 'true') {
        embMapping.flag = true;
      } else {
        embMapping.flag = false;
      }
      this.$forceUpdate();
      this.hideAllToggle();
    },
    uniqueColors(colorMapping) {
      /* eslint-disable */
      return _.chain(colorMapping).map('id').compact().uniq().orderBy().value();
    },
    async fetchImage(layer, toggle, side, baseType, image) {
      console.log(layer, toggle, side, 'check here');
      switch (side) {
        case 'Normal Front':
          this.normalFront = false;
          break;
        case 'Normal Back':
          this.normalBack = false;
          break;
        case 'Plus Front':
          this.plusFront = false;
          break;
        case 'Plus Back':
          this.plusBack = false;
          break;
      }
      const data = {};
      data.folderName = this.folderName;
      data.fileName = this.fileName;
      const result = await customizationService.fetchImageFromGoogleDrive(data);
      if (result.status === 200) {
        this.$toasted.show('Image added!.', {
          theme: 'bubble',
          position: 'bottom-center',
          duration: 1000,
        });
        this.boxImageUrl = result.data.downloadUrl;

        /* eslint-disable */
        _.each(this.categories, (category) => {
          _.each(category.layers, (layer) => {
            _.each(layer.toggles, (toggle) => {
              _.each(toggle.baseTypes, (baseType) => {
                if (baseType.id == this.selectedBaseId) {
                  const filterArray = _.filter(baseType.toggleImageBody, {
                    name: side,
                  });
                  filterArray[0].image = this.boxImageUrl;
                }
              });
            });
          });
        });
        // const filterArray = _.filter(toggle.toggleImageBody, {
        //   name: side,
        // });
        // filterArray[0].image = this.boxImageUrl;
        this.showFlag = false;
        this.showFlag = true;
      }
    },
    async fetchEmbImage(layer, toggle, side, name, baseType, baseIndex) {
      console.log(layer, toggle, side, 'check here');
      switch (side) {
        case 'Normal Front':
          this.normalFront = false;
          break;
        case 'Normal Back':
          this.normalBack = false;
          break;
        case 'Plus Front':
          this.plusFront = false;
          break;
        case 'Plus Back':
          this.plusBack = false;
          break;
      }
      const data = {};
      data.folderName = this.folderName;
      data.fileName = this.fileName;
      const result = await customizationService.fetchImageFromGoogleDrive(data);
      if (result.status === 200) {
        this.$toasted.show('Image added!.', {
          theme: 'bubble',
          position: 'bottom-center',
          duration: 1000,
        });

        this.boxImageUrl = result.data.downloadUrl;

        /* eslint-disable */

        /* eslint-disable */
        const filterArray = _.filter(toggle.baseTypes[baseIndex].embroidery, {
          name: name,
        });
        // const filterArray = _.filter(toggle.embroidery, {
        //   name: name,
        // });
        const filterEmbArray = _.filter(filterArray[0].images, {
          name: side,
        });
        filterEmbArray[0].image = this.boxImageUrl;
        this.showFlag = false;
        this.showFlag = true;
      }
    },
    goBack() {
      history.go(-1);
    },
    addBase(toggle) {
      var length = toggle.baseTypes.length + 1;
      let defaultObj = {
        name: 'Default' + '-' + length,
        id: uuid_v4(),
        showEmbroidery: false,
        toggleImageBody: [
          { name: 'Normal Front', image: 'IMG VALUE' },
          { name: 'Normal Back', image: 'IMG VALUE' },
          { name: 'Plus Front', image: 'IMG VALUE' },
          { name: 'Plus Back', image: 'IMG VALUE' },
        ],
        embroidery: [],
      };
      toggle.baseTypes.push(defaultObj);
    },

    removeBase(toggle, baseType) {
      _.remove(toggle.baseTypes, (n) => {
        const obj = n === baseType;
        return obj;
      });
      console.log('toggle', toggle);
      this.$forceUpdate();
    },
    async uploadFolderImages() {
      this.fetching = true;
      const data = {};
      data.name = this.BulkfolderName;
      const result = await productService.uploadImagesGoogleDrive(data);
      console.log(result, 'result-avi');
      result.data.map((item) => {
        var temp = {};
        temp.name = item.name;
        temp.downloadUrl = item.downloadUrl;
        this.boxImages.push(temp);
      });
      console.log(result, 'box upload');
      console.log(this.boxImages, 'box-imges');
      //await this.boxUpload();
      this.fetching = false;

      //
      this.entryAllEmbroidery();
      this.bulkImageUpload();
    },

    async bulkImageUpload() {
      _.each(this.categories, (category, i) => {
        // let categoryName = `${category.name}`;
        // let categoryIndex = i + 1;

        _.each(category.layers, (layer, j) => {
          // let layerName = `${layer.name}`;
          // let layerIndex = j + 1;

          _.each(layer.toggles, (toggle, k) => {
            let toggleName = `${toggle.name}`;
            let toggleIndex = k + 1;

            _.each(toggle.baseTypes, (baseType, l) => {
              let baseTypeName;
              switch (baseType.name) {
                case 'Default':
                  baseTypeName = 'D1';
                  break;
                case 'Default-1':
                  baseTypeName = 'D1';
                  break;
                case 'Default-2':
                  baseTypeName = 'D2';
                  break;
              }

              let baseTypeIndex = l + 1;

              _.each(baseType.toggleImageBody, (image) => {
                let toggleImgName = '';
                switch (image.name) {
                  case 'Normal Front':
                    toggleImgName = 'MF';
                    break;
                  case 'Normal Back':
                    toggleImgName = 'MB';
                    break;
                  case 'Plus Front':
                    toggleImgName = 'PF';
                    break;
                  case 'Plus Back':
                    toggleImgName = 'PB';
                    break;
                }
                let fileName = `${toggleName}-${toggleImgName}-${baseTypeName}`;
                console.log('avi-generatedfileurl', fileName);
                // const match = this.boxImages.find((box) => {
                //   box.name === fileName;
                // });

                let match = _.find(this.boxImages, function (image) {
                  let dotPosition = image.name.lastIndexOf('.');
                  let imageName =
                    dotPosition !== -1 ? image.name.substring(0, dotPosition) : image.name;
                  return imageName === fileName;
                });

                if (match) {
                  image.image = match.downloadUrl;
                } else {
                  this.unmatchedImageNames.push(fileName);
                }
              });

              _.each(baseType.embroidery, (emb, index) => {
                let embName = '';
                switch (index) {
                  case 0:
                    embName = 'E1';
                    break;
                  case 1:
                    embName = 'E2';
                    break;
                }
                _.each(emb.images, (img) => {
                  let embImgSide = '';
                  switch (img.name) {
                    case 'Normal Front':
                      embImgSide = 'MF';
                      break;
                    case 'Normal Back':
                      embImgSide = 'MB';
                      break;
                    case 'Plus Front':
                      embImgSide = 'PF';
                      break;
                    case 'Plus Back':
                      embImgSide = 'PB';
                      break;
                  }
                  let fileNameEmb = `${toggleName}-${embImgSide}-${baseTypeName}-${embName}`;
                  console.log(fileNameEmb, 'emb');
                  // const match = this.boxImages.find((box) => box.name === fileName);
                  let match = _.find(this.boxImages, function (image) {
                    let dotPosition = image.name.lastIndexOf('.');
                    let imageName =
                      dotPosition !== -1 ? image.name.substring(0, dotPosition) : image.name;
                    return imageName === fileNameEmb;
                  });
                  if (match) {
                    img.image = match.downloadUrl;
                    console.log(img.image);
                  } else {
                    this.unmatchedImageNames.push(fileNameEmb);
                  }
                });
              });
            });
          });
        });
      });
    },
    async entryAllEmbroidery() {
      _.each(this.categories, (category) => {
        _.each(category.layers, (layer) => {
          _.each(layer.toggles, (toggle) => {
            _.each(toggle.baseTypes, (baseType, baseIndex) => {
              console.log('baseIndex', baseIndex);
              this.createEmbImageArray(layer, toggle, category, baseType, baseIndex);
              this.$forceUpdate();
            });
          });
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/Scss/_main.scss';

.next-button-section {
  margin: 30px 0;
}
.image-upload-section {
  padding: 20px;
}
.sub-text {
  font-size: 12px;
  color: #999;
}
.category-tree {
  position: fixed;
  zoom: 0.7;
  top: 10px;
}
.embroidery-section {
  padding: 20px;
  background: #e0e1ff;
  border-radius: 20px;
}
.category-section {
  padding: 20px;
  background: #e0eeff;
  border-radius: 20px;
}
.layer-section {
  background: #e0fcfe;
  border-radius: 20px;
  padding: 45px;
  margin-top: 30px;
}
.toggle-section {
  background: #fee0e0;
  border-radius: 20px;
  padding: 20px;
}

.delete-button-holder {
  position: relative;
}
.delete-button {
  position: absolute;
  zoom: 0.7;
  left: -67px;
  top: 19px;
}
.show-button {
  zoom: 0.7;
}
.margin-left-1 {
  margin: 10px 0px 10px 80px;
}
.pull-right {
  float: right;
}
.padding-10 {
  padding: 10px;
}
.custom-range {
  margin-left: 30px;
  width: 220px;
}
.dialog-table {
  border: 1px solid black;
}
.image-preview {
  height: 100px;
  width: 80px;
}
.size-info {
  width: 150px !important;
  margin: auto;
  display: block;
}
.go-back {
  cursor: pointer !important;
}
.model-fixed {
  position: fixed;
  left: 50%;
  top: 12%;
  transform: translateX(-55%);
  transform: translateY(-5%);
  transform: scaleY(0.8);
  transform: scale(0.75);
  padding: 3em;
  max-height: 400px !important;
  max-width: 720px !important;
}
.toggle-name-text {
  text-transform: none !important;
}
@media only screen and (max-height: 679px) {
  .model-fixed {
    position: fixed;
    left: 50%;
    top: 10%;
    transform: translateX(-55%);
    transform: translateY(-5%);
    transform: scaleY(0.6);
    transform: scale(0.6);
    padding: 3em;
    max-height: 280px !important;
    max-width: 720px !important;
  }
}
</style>
